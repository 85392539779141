.card {
	transition: .5s ease;
	cursor: pointer;
	text-decoration: none !important;
	outline: none !important;
}

.card:hover {
	box-shadow: 0px 8px 6px -6px rgba(0, 0, 0, .9);
}

.card-link {
	text-decoration: none !important;
	outline: none !important;
}
